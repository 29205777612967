import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {formatPhoneNumber as formatPhoneNumberUs, formatPhoneNumberIntl} from "react-phone-number-input";

export function formatPhoneNumber(unformattedNumber) {
    if (!unformattedNumber) {
        return {
            formattedNumber: "",
            unformattedNumber: "",
        };
    }

    if (typeof unformattedNumber !== 'string') {
        unformattedNumber = String(unformattedNumber);
    }

    let formattedNumber;
    if (unformattedNumber[0] === "+") {
        // international phone number
        formattedNumber = formatPhoneNumberIntl(unformattedNumber);
    } else {
        // if no country code, assume US - formatPhoneNumber requires a country code to work
        formattedNumber = formatPhoneNumberUs("+1" + unformattedNumber);
    }
    return {
        formattedNumber,
        unformattedNumber,
    };
}

export default class PhoneNumber extends Component {
    static propTypes = {
        number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        isLinked: PropTypes.bool,
        className: PropTypes.string,
        ariaLabel: PropTypes.string,
        description: PropTypes.string,
    };

    static defaultProps = {
        isLinked: false,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {isLinked, number, ariaLabel, className, description, ...validDomAttributes} = this.props;
        const {formattedNumber, unformattedNumber} = formatPhoneNumber(number);

        return (
            <Fragment>
                {isLinked ? (
                    <div>
                        <a aria-label={ariaLabel || `telephone number ${unformattedNumber}`} href={`tel:${unformattedNumber}`} className={className} {...validDomAttributes}>
                            {formattedNumber}
                        </a>
                        {description && <h5 style={{display: 'inline', fontWeight: 900}}> {description}</h5>}
                    </div>
                ) : (
                    <div>
                        <span className={className} {...validDomAttributes}>
                            {formattedNumber}
                        </span>
                        {description && <h5 style={{display: 'inline', fontWeight: 900}}> {description}</h5>}
                    </div>
                )}
            </Fragment>
        );
    }
}
